import styled from 'styled-components';

export const BioSection = styled.section`
  padding: 32px 0;

  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  p {
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.sapphireBlue};
  }

  p:first-child {
    @media (min-width: 992px) {
      margin-top: 0;  
    }
  }

  .contact-info {
    p {
      margin: 5px 0;
    }
  }

`;

export const FormSection = styled.section`
  padding: 32px 0;
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  color: ${({ theme }) => theme.colors.white};
  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  form {
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    label {
      display: flex;
      flex-direction: column;
      font-weight: 300;
      margin: 25px 0;
      input {
        height: 40px;
      }
      input[type='email'], input[type='text'] {
        width: 60%;
        margin-top: 15px;
        @media (max-width: 992px) {
          width: 100%;
        }
      }
      input[type='radio'] {
        -webkit-appearance: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        outline: none;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0 0 5px 0px gray inset;
      }
      input[type='radio']:hover {
        box-shadow:0 0 5px 0px ${({ theme }) => theme.colors.sapphireBlue} inset;
      }
      input[type='radio']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;    
        border-radius: 50%;    
      }
      input[type='radio']:checked:before {
        background: ${({ theme }) => theme.colors.sapphireBlue};
      }
    }
    label[for="event"], label[for="lesson"], label[for="press"], label[for="inquiry"] {
      display: flex;
      flex-direction: row;
      font-size: 20px;
      align-items: center;
      margin: 15px 0;
      input {
        margin-right: 15px;
      }
    }
    label[for="message"] {
      margin: 20px 0;
    }
    input[type="submit"] {
      width: 80px;
      margin-left: auto;
      display: inline-block;
      padding: 12px 24px;
      text-transform: uppercase;
      border: none;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.inkyBlack};
      font-family: ${({ theme }) => theme.fonts.roboto};
      font-size: 16px;
      min-width: 160px;
      cursor: pointer;
      text-align: center;
      margin-top: 50px;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }
  }
`;

export const InstagramSection = styled.section`
  padding: 32px 0;
  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  h2 {
    color: ${({ theme }) => theme.colors.sapphireBlue}; 
  }

  .slick-track {
    display: flex !important;
  }

  .slick-arrow {
    background: transparent;
    z-index: 2;
    height: 100%;
    width: 60px;
    opacity: 0.75;
    &.slick-prev {
      left: 0px;
      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900;
        content: '\f053';
      }
    }
    &.slick-next {
      right: 0px;
      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900;
        content: '\f054';
      }
    }
    &:before {
      color: ${({ theme }) => theme.colors.sapphireBlue}; 
      position: absolute;
      top: calc(50%);
      left: calc(50%);
    }
  }

  
  .slick-current {
    margin-right: 5px;
  } 
  .slick-current + div {
    margin: 0 5px;
  }
  .slick-current + div + div {
    margin: 0 10px 0 5px;
  }

  .slick-slide {
    height: 300px !important;

    @media (min-width: 781px) {
      height: 250px !important;
    }
    > div {
      height: inherit !important;
      .image-slider-container {
        height: inherit !important;
      }
    }
    img {
      object-fit: contain !important;
    }
  }
`;

export const StyledUl = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto 16px;
  width: 90%;
  max-width: 360px;

  @media (min-width: 1024px) {
    margin: 0;
  }

  .icon {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.sapphireBlue};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 10px;
  }

  .icon.spotify {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.sapphireBlue};
    padding: 0;
  }
  .icon.bandcamp {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.sapphireBlue};
    padding: 0;
  }
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

export const StyledH2 = styled.h2`
  margin: 24px auto;
  text-align: center;

  @media (min-width: 992px) {
    margin: 32px auto;
    margin-bottom: 48px;
  }
`;
