import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import Layout from "../components/layout"
import SEO from "../components/seo"
import parse from 'html-react-parser'
import Img from "gatsby-image"
import _get from "lodash/get";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faItunesNote,
  faBandcamp,
  faSpotify,
  faFacebookF,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

import { BioSection, FormSection, InstagramSection, StyledH1, StyledH2, StyledUl } from '../styles/contactPageStyles';

const ContactPage = ( { data } ) => {
  const contactPicture = data.contentfulContactPage.contactPicture.fluid;
  const contactWriteup = data.contentfulContactPage.contactWriteup.childMarkdownRemark.html;
  const contactPhoneNumber = data.contentfulContactPage.phoneNumber;
  const contactEmail = data.contentfulContactPage.email;
  const socialMediaLinks = data.contentfulSocialMediaLinks;
  const arrayOfInstaImages = data.allInstagramContent.edges;
  const allLinks = data.allContentfulSocialMediaLinks.edges[0].node;

  const submitForm = () => {
    // Get the first form with the name
    // Usually the form name is not repeated
    // but duplicate names are possible in HTML
    // Therefore to work around the issue, enforce the correct index
    var frm = document.getElementsByName('contact-form')[0];
    frm.submit(); // Submit the form
    frm.reset();  // Reset all form data
    return false; // Prevent page refresh
 }

  // gets window dimensions to conditionally render amount of images in carrousel
  const windowGlobal = typeof window !== 'undefined' && window;
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = windowGlobal;
    return {
      width,
      height
    };
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      windowGlobal.addEventListener('resize', handleResize);
      return () => windowGlobal.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  const [imageCount, setImageCount] = useState(1);

  useEffect(() => {
    if(width >= 1200) {
      setImageCount(4);
    } else if (width >= 1024) {
      setImageCount(3);
    }  else if (width >= 781 ) {
      setImageCount(2);
    } else if (width <= 780) {
      setImageCount(1);
    }
  }, [width]);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#E4E5EA" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#E4E5EA" }}
        onClick={onClick}
      />
    );
  }

  const imageMap = data.allInstagramContent.edges.map((image, i) => {
    let theFluid = image.node.localImage.childImageSharp.fluid;
      return (
        <div className="image-slider-container" key={i}>
          <Img className="image-slider-iteree" fluid={theFluid} />
        </div>
      )
  });

  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    speed: 500,
    adaptiveHeight: false,
    slidesToShow: imageCount,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const renderForm = () => {

    return (
      <form method="POST" action="https://formspree.io/f/myylgnbz" name="contact-form">
        <label>
          Name
          <input required type="text" name="name" />
        </label>
        <label>
          Company (optional)
          <input type="text" name="company" />
        </label>
        <label>
          Your Email
          <input required type="email" name="_replyto" />
        </label>

        <p>Reason for Inquiry</p>
        <label for="event">
          <input type="radio" id="event" name="reason" value="event" defaultChecked/>
          Book an event
        </label>

        <label for="lesson">
          <input type="radio" id="lesson" name="reason" value="lesson"/>
          Guitar lessons
        </label>

        <label for="press">
          <input type="radio" id="press" name="reason" value="press"/>
          Press/media
        </label>

        <label for="inquiry">
          <input type="radio" id="inquiry" name="reason" value="inquiry"/>
          Other inquiries
        </label>
        <label for="message">Message</label>
          <textarea id="message" name="message" rows="15" cols="100">
          </textarea>

        <input value="submit" type="submit" onclick={submitForm}/>
      </form>
    )
  }

  const renderSocialMedia = () => {
    return (
      <Row className="d-md-flex align-items-center mt-3">
        <Col xs={12}>
          <StyledUl>
            <li><a href={allLinks.instagramUrl} target="_blank"><FontAwesomeIcon icon={faInstagram} className="icon" /></a></li>
            <li><a href={allLinks.appleMusicUrl} target="_blank"><FontAwesomeIcon icon={faItunesNote} className="icon" /></a></li>
            <li><a href={allLinks.bandcampUrl} target="_blank"><FontAwesomeIcon icon={faBandcamp} className="icon bandcamp" /></a></li>
            <li><a href={allLinks.spotifyUrl} target="_blank"><FontAwesomeIcon icon={faSpotify} className="icon spotify" /></a></li>
            <li><a href={allLinks.facebookUrl} target="_blank"><FontAwesomeIcon icon={faFacebookF} className="icon" /></a></li>
            <li><a href={allLinks.twitterUrl} target="_blank"><FontAwesomeIcon icon={faTwitter} className="icon" /></a></li>
          </StyledUl>
        </Col>
      </Row>
    )
  }

  return (
    <Layout>
      <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      <SEO title="Contact Page" />
      <BioSection>
        <Container>
          <Row>
            <Col xs={12}>
              <StyledH1>Contact</StyledH1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <Img fluid={contactPicture} />
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }} className="d-flex flex-column justify-content-center">
              <div className="contact-writeup mt-3">
                {parse(contactWriteup)}
              </div>
              <div className="contact-info my-3 my-md-4 my-lg-5">
                <p>{contactPhoneNumber}</p>
                <p>{contactEmail}</p>
              </div>
              {renderSocialMedia()}
            </Col>
          </Row>
        </Container>
      </BioSection>
      <FormSection>
        <Container>
          <StyledH2>Contact Chris</StyledH2>
          {renderForm()}
        </Container>
      </FormSection>
      <InstagramSection>
        <Container>
          <StyledH2>Follow on Instagram</StyledH2>
          <p class="d-flex justify-content-center my-4 my-md-5"><a target="_blank" href="https://www.instagram.com/chrisplattmusic/">@chrisplattmusic</a></p>
          <Slider {...settings}>
            {imageMap}
          </Slider>
        </Container>
      </InstagramSection>
    </Layout>
  );
}

export default ContactPage;

export const pageQuery = graphql`
  query contactPageQuery {
    contentfulContactPage {
      contactPicture {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
      contactWriteup {
        childMarkdownRemark {
          html
        }
      }
      phoneNumber
      email
    }
    contentfulSocialMediaLinks {
      appleMusicUrl
      bandcampUrl
      instagramUrl
      facebookUrl
      spotifyUrl
      twitterUrl
    }
		allInstagramContent {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
              fixed {
                aspectRatio
                base64
                height
                originalName
                src
                srcSet
              }
            }
          }
        }
      }
    }
    allContentfulSocialMediaLinks {
      edges {
        node {
          appleMusicUrl
          bandcampUrl
          facebookUrl
          instagramUrl
          spotifyUrl
          twitterUrl
        }
      }
    }
  }
`
